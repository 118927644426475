import * as React from 'react';
import Helmet from 'react-helmet';

import Twitter from 'assets/svg/twitter.svg';

import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { Link as HeaderLink } from 'components/header/Link';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';

import s from './AppLayout.scss';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      <HeaderLink
        name="twitter"
        to="https://twitter.com/formlancer"
        icon={<Twitter />}
      />
    </Header>

    {children}

    <Footer
      social={[
        { icon: <Twitter />, to: 'https://twitter.com/formlancer' },
      ]}
    />

    {isDev && <Devtools />}
  </div>
);
